<template>
  <div class="appointments waiting-time">
    <div v-if="loading" class="loading-wrap" style="padding: 20px 0 0 0">
      <span class="loading-spinner"></span>
    </div>
    <div v-else>
      <h3>Geschätzte Wartezeiten</h3>
      <div class="waiting-wrap">
        <h2>Bürgerbüro</h2>
        <h4>{{ stats.estimated_time }} Minuten</h4>
        <p>{{ stats.running }} laufende(r) Termin(e) | {{ stats.waiting_line }} Termin(e) in der Warteschlange</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'appointment_waiting_time',
  data () {
    return {
      loading: true,
      stats: {}
    }
  },
  methods: {
    async get_data() {
      this.$http.get(process.env.VUE_APP_BASE_API+'/appointments/waiting_time', { headers: { Authorization: this.$store.getters.get_token } })
      .then(response => {
        this.stats = response.data;
        this.loading = false;
      })
    },
  },
  mounted () {
    this.get_data();
  }
}
</script>

<style lang="scss">

  .waiting-time {
    text-align: center;
    padding: 40px 0 20px 0;

    h3 {
      margin: 0;
    }

    h4 {
      margin: 20px 0 0 0;
      font-size: 44px;
    }

    p {
      margin-top: 30px;
      font-size: 14px;
    }
  }

  .waiting-wrap {
    margin-top: 30px;
    border: 2px dashed #f1f1f1;
    padding: 20px;
    border-radius: 10px;
  }

</style>
